/* eslint-disable react/display-name */
import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@emotion/react";
import { lightTheme } from "../styles/themes";

import messages_fr from "../translations/fr.json";
import messages_en from "../translations/en.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
};

let lang;
const browser = typeof window !== "undefined" && window;
if (browser) {
  const storedLang = window.localStorage.getItem("pw.lang");
  if (storedLang) {
    lang = storedLang;
  } else {
    window.localStorage.setItem("pw.lang", "en");
    lang = "en";
  }
}

export default ({ children }) => {
  const themeToUse = lightTheme;

  return (
    <ThemeProvider theme={themeToUse}>
      <IntlProvider locale={lang} messages={messages[lang]}>
        {children}
      </IntlProvider>
    </ThemeProvider>
  );
};
